import React from 'react'
import { TaskContainer } from './task.styles'
import { Box, Typography } from '@common/components'
import parse from 'html-react-parser'

const Task = (props: any) => {
  const { note } = props
  return (
    <TaskContainer
      sx={{
        background: note?.color ?? '#FFF0C8',
        flex: 1,
      }}
    >
      <Box sx={{ flex: 1 }}>
        {note?.questions.map((question, index) => (
          <Typography key={index} variant='body2'>
            {parse(question.text)}
          </Typography>
        ))}
      </Box>
    </TaskContainer>
  )
}

export default Task
