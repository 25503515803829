// auth routes
export const MAIN_ROUTE = '/'
export const LOGIN_ROUTE = '/login'
export const SIMPLE_LOGIN_ROUTE = '/slogin'
export const REGISTER_ROUTE = '/register'
export const VERIFY_ROUTE = '/verify'
export const LOGIN_AS_GUEST_ROUTE = '/login_as_guest'
export const TRY_PENGGUIN_ROUTE = '/try_pengguin'
export const FORGET_ROUTE = '/forget_password'
export const REGISTER_COMPANY_ROUTE = '/company/start'

// app routes
export const PROFILE_ROUTE = '/profile'
export const SETTINGS_ROUTE = `${PROFILE_ROUTE}/settings`
export const BIO_ROUTE = `${PROFILE_ROUTE}/my-teacher-bio`
export const CUSTOM_OPTION_ROUTE = `${PROFILE_ROUTE}/custom-options`
export const TEACHER_PRICES = `${PROFILE_ROUTE}/teacher-prices`
export const TEACHER_AFFILIATION_OFFERS = `${PROFILE_ROUTE}/affliation-offers`
export const TEACHER_CONNECT_ROUTE = `${PROFILE_ROUTE}/teacher-connect`
export const FEEDBACK_SERVICES_ROUTE = `${PROFILE_ROUTE}/feedback-services`
export const VIDEO_ROUTE = `${PROFILE_ROUTE}/my-videos`
export const PERSONAL_ROUTE = `${PROFILE_ROUTE}/personal-information`
export const LANGUAGES_ROUTE = `${PROFILE_ROUTE}/languages`
export const BENEFIT = `${PROFILE_ROUTE}/invite-your-friends`
export const AVAILABILITY_ROUTE = `${PROFILE_ROUTE}/availability`
export const SUBSCRIPTION_ROUTE = `${PROFILE_ROUTE}/subscription`
export const PROFILE_NOTIFICATION_ROUTE = `${PROFILE_ROUTE}/notification`

export const PREFERRED_TEACHER = `${PROFILE_ROUTE}/group-settings`

export const DASHBOARD_ROUTE = '/dashboard/recordings'
export const OVERVIEW_ROUTE = '/dashboard/overview'
export const NOTES_ROUTE = '/dashboard/notes'
export const DOCUMENTS_ROUTE = '/dashboard/documents'
export const VOCABULARY_ROUTE = '/dashboard/vocabulary'
export const LIVE_BOARDS_ROUTE = '/dashboard/live_boards'
export const WEBLINKS_ROUTE = '/dashboard/weblinks'
export const FEEDBACK_ROUTE = '/dashboard/feedback'
export const SUMMARY_ROUTE = '/dashboard/summary'

export const COMPANY_ACCOUNT_ROUTE = '/company/account'

export const COMMUNITY_ROUTE = '/community'
export const USERNAME_ROUTE = (username: any) => `/user/${username}`
export const BOOKING_ROUTE = '/booking'
export const MY_CLASSROOMS_ROUTE = '/events'
export const INVENTORY_ROUTE = '/inventory'
export const EVENT_ROUTE = '/classroom'
export const DEMO_ROUTE = '/demo'
export const EVENT = (id: any) => `/classroom/${id}`
export const NEXT_EVENT = '/live'
export const FIND_A_TEACHER_ROUTE = 'find-a-teacher'
export const RECORDINGS_ROUTE = '/dashboard/recordings'
export const TESTMYSELF_ROUTE = '/dashboard/test-myself'
export const QUIZ_VOCAB_ROUTE = '/dashboard/quiz-vocabulary'
// Dashboard Admin
export const STATISTIC_ROUTE = '/dashboard/performance'
export const USAGE_ROUTE = '/dashboard/usage'
export const TABLE_VIEW_ROUTE = '/dashboard/table-view'

export const GROUP_LESSON_ROUTE = (id: any) => `/group-lesson/${id}`
export const PRICING_ROUTE = '/pricing'
export const PAYMENT_ROUTE = '/payment'
export const CHECKOUT_SUCCESS_ROUTE = '/checkout/success'

export const PREFERRED_ACCENT_ROUTE = `${PROFILE_ROUTE}/preferred-accent`

// external
export const FEEDBACK = 'https://feedback.pengguin.com'
export const BLOG = 'https://www.pengguin.com/blog'
export const FOR_SCHOOLS = 'https://www.languagegames.co/interplay'
export const TERMS_OF_USE =
  'https://ispeak.notion.site/Terms-and-Conditions-a07be00c0cb14e728bf66988141055c2?pvs=4'
export const PRIVACY_POLICY =
  'https://pengg.notion.site/I-SPEAK-Privacy-Policy-8253f7935d3f4e2bb2176f34c7b0a13b'
