import {
  FEEDBACK_ROUTE,
  LOGIN_ROUTE,
  NOTES_ROUTE,
  PERSONAL_ROUTE,
  PREFERRED_ACCENT_ROUTE,
  PROFILE_NOTIFICATION_ROUTE,
  STATISTIC_ROUTE,
  USAGE_ROUTE,
  TABLE_VIEW_ROUTE,
  PROFILE_ROUTE,
  RECORDINGS_ROUTE,
  SUMMARY_ROUTE,
} from '@common/constants/routes'
import { createBrowserRouter } from '@lib/router'
import { wrapCreateBrowserRouter } from '@sentry/react'
import { lazy, Suspense } from 'react'

import AppLayout from '@common/layout/app-layout'
import AuthLayout from '@common/layout/auth-layout'
import DashboardLayout from '@common/layout/dashboard-layout'
import ErrorBoundary from '@common/layout/error-boundary'
import PageLayout from '@common/layout/page-layout'
import ProfileLayout from '@common/layout/profile-layout'
import RootLayout from '@common/layout/root-layout'
import { notesLoader, StickyNotesPlaceholder } from '@pages/dashboard/sticky-notes'
import { LoginPlaceholder } from '@pages/login'
import { NotFoundPlaceholder } from '@pages/not-found'
import { profileLoader, ProfilePlaceholder } from '@pages/profile'
import Accent from '@pages/profile/acccent'
import { PersonalDetailsPlaceholder } from '@pages/profile/personal-details'
const Feedback = lazy(() => import('@pages/dashboard/feedback'))
const Statistic = lazy(() => import('@pages/dashboard/statistic'))
const Usage = lazy(() => import('@pages/dashboard/usage'))
const TableView = lazy(() => import('@pages/dashboard/table-view'))
const FeedbackById = lazy(() => import('@pages/dashboard/feedback/[feedback_id]'))
const Login = lazy(() => import('@pages/login'))
const Summary = lazy(() => import('@pages/(unsw)/summary'))
const SummaryById = lazy(() => import('@pages/(unsw)/summary/[summary_id]'))
const StickyNotes = lazy(() => import('@pages/dashboard/sticky-notes'))
const PersonalDetails = lazy(() => import('@pages/profile/personal-details'))
const NotificationSettings = lazy(() => import('@pages/profile/notification-settings'))
const NotFound = lazy(() => import('@pages/not-found'))
const Recordings = lazy(() => import('@pages/dashboard/recordings'))

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <AuthLayout />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            index: true,
            element: (
              <PageLayout public title='Welcome to Mentor AI' fallback={<LoginPlaceholder />}>
                <Login />
              </PageLayout>
            ),
          },
          {
            path: LOGIN_ROUTE,
            element: (
              <PageLayout public title='Welcome to Mentor AI' fallback={<LoginPlaceholder />}>
                <Login />
              </PageLayout>
            ),
          },
        ],
      },
      {
        element: <AppLayout />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: PROFILE_ROUTE,
            loader: profileLoader,
            element: (
              <Suspense fallback={<ProfilePlaceholder />}>
                <ProfileLayout />
              </Suspense>
            ),
            children: [
              {
                path: PERSONAL_ROUTE,
                element: (
                  <PageLayout
                    protected
                    title='Account - personal information'
                    fallback={<PersonalDetailsPlaceholder />}
                  >
                    <PersonalDetails />
                  </PageLayout>
                ),
              },
              {
                path: PROFILE_NOTIFICATION_ROUTE,
                element: (
                  <PageLayout
                    protected
                    title='Notifications'
                    fallback={<PersonalDetailsPlaceholder />}
                  >
                    <NotificationSettings />
                  </PageLayout>
                ),
              },
              {
                path: PREFERRED_ACCENT_ROUTE,
                element: (
                  <PageLayout
                    protected
                    title={'Account - preferred accent'}
                    fallback={<PersonalDetailsPlaceholder />}
                  >
                    <Accent />
                  </PageLayout>
                ),
              },
              {
                path: '*',
                element: (
                  <PageLayout title='Not found' fallback={<NotFoundPlaceholder />}>
                    <NotFound />
                  </PageLayout>
                ),
              },
            ],
          },
          {
            element: (
              <PageLayout title='Dashboard' protected fallback={null}>
                <DashboardLayout />
              </PageLayout>
            ),
            children: [
              {
                path: SUMMARY_ROUTE,
                children: [
                  {
                    index: true,
                    element: (
                      <PageLayout title='Dashboard - Summary' fallback={<StickyNotesPlaceholder />}>
                        <Summary />
                      </PageLayout>
                    ),
                  },
                  {
                    path: `${SUMMARY_ROUTE}/:feedback_id`,
                    element: (
                      <PageLayout title='Dashboard - Summary' fallback={<StickyNotesPlaceholder />}>
                        <SummaryById />
                      </PageLayout>
                    ),
                  },
                ],
              },
              {
                path: NOTES_ROUTE,
                loader: notesLoader,
                element: (
                  <PageLayout
                    roles={['TEACHER']}
                    title='Dashboard - sticky notes'
                    fallback={<StickyNotesPlaceholder />}
                  >
                    <StickyNotes />
                  </PageLayout>
                ),
              },
              {
                path: FEEDBACK_ROUTE,
                children: [
                  {
                    index: true,
                    element: (
                      <PageLayout
                        title='Dashboard - Feedback'
                        fallback={<StickyNotesPlaceholder />}
                      >
                        <Feedback />
                      </PageLayout>
                    ),
                  },
                  {
                    path: `${FEEDBACK_ROUTE}/:feedback_id`,
                    element: (
                      <PageLayout
                        title='Dashboard - Feedback'
                        fallback={<StickyNotesPlaceholder />}
                      >
                        <FeedbackById />
                      </PageLayout>
                    ),
                  },
                ],
              },
              {
                path: RECORDINGS_ROUTE,
                element: (
                  <PageLayout title='Dashboard - workshops' fallback={<StickyNotesPlaceholder />}>
                    <Recordings />
                  </PageLayout>
                ),
              },
              {
                path: USAGE_ROUTE,
                element: (
                  <PageLayout
                    // roles={['ADMIN']}
                    title='Dashboard - statistics'
                    fallback={<StickyNotesPlaceholder />}
                  >
                    <Usage />
                  </PageLayout>
                ),
              },
              {
                path: TABLE_VIEW_ROUTE,
                element: (
                  <PageLayout
                    // roles={['ADMIN']}
                    title='Dashboard - Table View'
                    fallback={<StickyNotesPlaceholder />}
                  >
                    <TableView />
                  </PageLayout>
                ),
              },
              {
                path: STATISTIC_ROUTE,
                element: (
                  <PageLayout
                    // roles={['ADMIN']}
                    title='Dashboard - statistics'
                    fallback={<StickyNotesPlaceholder />}
                  >
                    <Statistic />
                  </PageLayout>
                ),
              },
              // {
              //   path: TESTMYSELF_ROUTE,
              //   element: (
              //     <PageLayout title='Dashboard - test myself' fallback={<WeblinksPlaceholder />}>
              //       <TestMyself />
              //     </PageLayout>
              //   ),
              // },
              {
                path: '*',
                element: (
                  <PageLayout
                    title='Mentor AI  - Page not found'
                    fallback={<NotFoundPlaceholder />}
                  >
                    <NotFound />
                  </PageLayout>
                ),
              },
            ],
          },
          {
            path: '*',
            element: (
              <PageLayout title='Not found' fallback={<NotFoundPlaceholder />}>
                <NotFound />
              </PageLayout>
            ),
          },
        ],
      },
    ],
  },
])

export default router

// TODO : add site map
export const siteMap = {
  // HomePage: {
  //   title: "Home",
  //   path: "/",
  //   description: "My home page",
  // },
  // SubPage: {
  //   title: "Sub Page",
  //   path: "/sub-page",
  //   description: "My sub page",
  // },
}
