import React from 'react'
import { Tab, Tabs, Typography } from '@common/components'
import {
  DashboardCustomizeIcon,
  DataUsageIcon,
  FactCheckIcon,
  FeedIcon,
  LyricsIcon,
  RadioButtonCheckedIcon,
} from '@common/icons'
import { RouterLink, useParams } from '@lib/router'
import {
  FEEDBACK_ROUTE,
  NOTES_ROUTE,
  QUIZ_VOCAB_ROUTE,
  RECORDINGS_ROUTE,
  STATISTIC_ROUTE,
  SUMMARY_ROUTE,
  TABLE_VIEW_ROUTE,
  USAGE_ROUTE,
} from '@common/constants/routes'
import { useTheme } from '@features/theme/theme.hooks'
import { useUser } from '@features/auth/hooks'
import StatisticIcon from '../../icons/statistic-icon'
import BookIcon from '../../icons/book-icon'
import TabsIcon from '../../icons/tabs-icon'

const DashboardTabs = (props) => {
  const { value, onChange, navLinks } = props
  const { feedback_id } = useParams()
  const { isStudent, user } = useUser()
  const { isDark } = useTheme()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onChange(newValue)
  }

  const studentTabs = [
    {
      value: RECORDINGS_ROUTE,
      label: 'Recording',
      icon: RadioButtonCheckedIcon,
    },
    {
      value:
        (location.pathname.startsWith(FEEDBACK_ROUTE) && feedback_id) ||
        (location.pathname.startsWith(SUMMARY_ROUTE) && feedback_id)
          ? SUMMARY_ROUTE + `/${feedback_id}`
          : SUMMARY_ROUTE,
      label: 'Summary',
      icon: DashboardCustomizeIcon,
      id: 'summary-tab-button',
    },
    {
      value:
        (location.pathname.startsWith(FEEDBACK_ROUTE) && feedback_id) ||
        (location.pathname.startsWith(SUMMARY_ROUTE) && feedback_id)
          ? FEEDBACK_ROUTE + `/${feedback_id}`
          : FEEDBACK_ROUTE,
      label: 'Feedback',
      icon: LyricsIcon,
      id: 'feedback-tab-button',
    },
  ]

  const teacherTabs = [
    { value: NOTES_ROUTE, label: 'Speaking Tasks', icon: FeedIcon, id: 'speaking-tasks' },
    {
      value:
        (location.pathname.startsWith(FEEDBACK_ROUTE) && feedback_id) ||
        (location.pathname.startsWith(SUMMARY_ROUTE) && feedback_id)
          ? FEEDBACK_ROUTE + `/${feedback_id}`
          : FEEDBACK_ROUTE,
      label: 'Feedback',
      icon: LyricsIcon,
    },
    {
      value:
        (location.pathname.startsWith(FEEDBACK_ROUTE) && feedback_id) ||
        (location.pathname.startsWith(SUMMARY_ROUTE) && feedback_id)
          ? SUMMARY_ROUTE + `/${feedback_id}`
          : SUMMARY_ROUTE,
      label: 'Test Result',
      icon: FactCheckIcon,
      id: 'summary-tab-button',
    },
  ]

  const adminTabs = [
    {
      value: USAGE_ROUTE,
      label: 'Class view',
      icon: BookIcon,
      id: 'class-view',
      iconCondition: location.pathname.startsWith(QUIZ_VOCAB_ROUTE) ? '#000' : '#747474',
    },
    {
      value: USAGE_ROUTE,
      label: 'USAGE',
      icon: DataUsageIcon,
      id: 'usage-view',
      // iconCondition: location.pathname.startsWith(USAGE_ROUTE) ? '#000' : '#747474',
    },
    {
      value: STATISTIC_ROUTE,
      label: 'PERFORMANCE',
      icon: StatisticIcon,
      id: 'statiscs-view',
      iconCondition: location.pathname.startsWith(STATISTIC_ROUTE) ? '#000' : '#747474',
    },
    {
      value: TABLE_VIEW_ROUTE,
      label: 'TABLE VIEW',
      icon: TabsIcon,
      id: 'table-view',
      iconCondition: location.pathname.startsWith(TABLE_VIEW_ROUTE) ? '#000' : '#747474',
    },
  ]

  let arrayTabs
  const hasSuperAdminRole = user?.roles && user?.roles.some((role) => role.name === 'SuperAdmin')

  if (hasSuperAdminRole) {
    arrayTabs = adminTabs
  } else if (user?.status === 'STUDENT') {
    arrayTabs = studentTabs
  } else if (user?.status === 'TEACHER') {
    arrayTabs = teacherTabs
  }
  // switch (user?.status) {
  //   case 'STUDENT':
  //     arrayTabs = studentTabs
  //     break
  //   case 'TEACHER':
  //     arrayTabs = teacherTabs
  //     break
  //   case 'ADMIN':
  //     arrayTabs = adminTabs
  //     break
  //   default:
  //     arrayTabs = studentTabs // Default or fallback array
  //   default:
  //     arrayTabs = adminTabs // Default or fallback array
  // }

  // TODO make it from the same array
  if (navLinks) {
    return (
      <>
        <Tabs value={value}>
          {arrayTabs.map((elem, index) => {
            const Icon = elem.icon
            return (
              <Tab
                key={index}
                icon={<Icon color={elem?.iconCondition ?? 'inherit'} />}
                component={RouterLink}
                to={elem.value}
                label={<Typography variant='caption'>{elem.label}</Typography>}
                value={elem.value}
                id={elem.id}
              />
            )
          })}
        </Tabs>
      </>
    )
  }
  return (
    <>
      <Tabs value={value} onChange={handleChange}>
        {arrayTabs.map((elem, index) => {
          const Icon = elem.icon
          return (
            <Tab
              key={index}
              icon={<Icon />}
              value={elem.value}
              label={<Typography variant='caption'>{elem.label}</Typography>}
            />
          )
        })}
      </Tabs>
    </>
  )
}

export default DashboardTabs
