import { Outlet } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@common/utils/styles'
import lightThemeUnsw from '@styles/themes/unsw-light-theme'

const AuthLayout = () => {
  return (
    <ThemeProvider theme={lightThemeUnsw}>
      <CssBaseline />
      <Outlet />
    </ThemeProvider>
  )
}

export default AuthLayout
