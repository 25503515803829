import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    whiteboardStartWhiteboard: build.mutation<
      WhiteboardStartWhiteboardApiResponse,
      WhiteboardStartWhiteboardApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/whiteboard/${queryArg.eventId}/start`,
        method: 'POST',
        params: { exit_: queryArg.exit },
      }),
    }),
    whiteboardExitWhiteboard: build.mutation<
      WhiteboardExitWhiteboardApiResponse,
      WhiteboardExitWhiteboardApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/whiteboard/${queryArg.eventId}/exit`,
        method: 'POST',
      }),
    }),
    whiteboardCreatePermission: build.mutation<
      WhiteboardCreatePermissionApiResponse,
      WhiteboardCreatePermissionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/whiteboard/${queryArg.eventId}/user_permission`,
        method: 'POST',
        body: queryArg.whiteBoardPermissionRequest,
      }),
    }),
    whiteboardListPermission: build.query<
      WhiteboardListPermissionApiResponse,
      WhiteboardListPermissionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/whiteboard/${queryArg.eventId}/user_permissions`,
      }),
    }),
    whiteboardDeleteAllPermissions: build.mutation<
      WhiteboardDeleteAllPermissionsApiResponse,
      WhiteboardDeleteAllPermissionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/whiteboard/${queryArg.eventId}/user_permissions`,
        method: 'DELETE',
      }),
    }),
    whiteboardDeletePermission: build.mutation<
      WhiteboardDeletePermissionApiResponse,
      WhiteboardDeletePermissionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/whiteboard/${queryArg.eventId}/user_permission/${queryArg.participantId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type WhiteboardStartWhiteboardApiResponse = /** status 200 Successful Response */ any
export type WhiteboardStartWhiteboardApiArg = {
  eventId: string
  exit?: boolean
}
export type WhiteboardExitWhiteboardApiResponse = /** status 200 Successful Response */ any
export type WhiteboardExitWhiteboardApiArg = {
  eventId: string
}
export type WhiteboardCreatePermissionApiResponse = /** status 201 Successful Response */ any
export type WhiteboardCreatePermissionApiArg = {
  eventId: string
  whiteBoardPermissionRequest: WhiteBoardPermissionRequest
}
export type WhiteboardListPermissionApiResponse =
  /** status 200 Successful Response */ WhiteBoardPermissionResponse[]
export type WhiteboardListPermissionApiArg = {
  eventId: string
}
export type WhiteboardDeleteAllPermissionsApiResponse = /** status 200 Successful Response */ any
export type WhiteboardDeleteAllPermissionsApiArg = {
  eventId: string
}
export type WhiteboardDeletePermissionApiResponse = /** status 200 Successful Response */ any
export type WhiteboardDeletePermissionApiArg = {
  eventId: string
  participantId: number
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type WhiteBoardPermissionEnum = any
export type WhiteBoardPermissionRequest = {
  permissions: WhiteBoardPermissionEnum[]
  participant_id: number
}
export type WhiteBoardPermissionResponse = {
  permissions: string[]
  participant_id: number
  event_id: number
}
export const {
  useWhiteboardStartWhiteboardMutation,
  useWhiteboardExitWhiteboardMutation,
  useWhiteboardCreatePermissionMutation,
  useWhiteboardListPermissionQuery,
  useLazyWhiteboardListPermissionQuery,
  useWhiteboardDeleteAllPermissionsMutation,
  useWhiteboardDeletePermissionMutation,
} = injectedRtkApi
