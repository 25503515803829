import { Box, Divider, Stack, Link as MuiLink } from '@mui/material'
import { Link } from 'react-router-dom'
import { PRIVACY_POLICY, TERMS_OF_USE } from '@common/constants/routes'
import { Typography, ContainerBottom } from './footer.styles'

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#0C0F13' }}>
      <Divider sx={{ backgroundColor: 'rgb(180,180,180)' }} />
      <ContainerBottom>
        <Typography variant='caption' color='rgb(180,180,180)'>
          All rights reserved ©{' '}
          <MuiLink underline='hover' sx={{ color: 'rgb(180,180,180)' }} href={'https://ispeak.to'}>
            iSpeak
          </MuiLink>{' '}
          2024.
        </Typography>
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ flex: 1 }}>
          <Stack component='ul' direction='row' spacing={2} sx={{ listStyleType: 'none' }}>
            {[
              {
                label: 'Terms of Use',
                to: TERMS_OF_USE,
              },
              {
                label: 'Privacy Policy',
                to: PRIVACY_POLICY,
              },
              // { label: t('footer.blog', 'Blog'), to: BLOG, external: true },
              // {
              //   label: t('footer.schools', 'Schools'),
              //   to: FOR_SCHOOLS,
              //   external: true,
              // },
            ].map((link, index) => (
              <MuiLink
                key={index}
                variant='caption'
                underline='hover'
                component={Link}
                to={link.to}
                color='rgb(180,180,180)'
              >
                {link.label}
              </MuiLink>
            ))}
          </Stack>
          {/* <Stack direction='row' alignItems='center'>
            <AutoAwesomeIcon sx={{ color: 'yellow', fontSize: '15px', mr: 0.5 }} />
            <MuiLink
              to={PRICING_ROUTE}
              variant='caption'
              underline='hover'
              component={Link}
              color='white'
            >
              Upgrade your account
            </MuiLink>
          </Stack> */}
        </Stack>
      </ContainerBottom>
    </Box>
  )
}

export default Footer
