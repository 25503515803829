import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    weblinksCreateWeblink: build.mutation<
      WeblinksCreateWeblinkApiResponse,
      WeblinksCreateWeblinkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/weblinks/weblink`,
        method: 'POST',
        body: queryArg.createWeblinkRequest,
      }),
    }),
    weblinksGetWeblinks: build.query<WeblinksGetWeblinksApiResponse, WeblinksGetWeblinksApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/weblinks/weblink`,
        params: { query: queryArg.query, is_public: queryArg.isPublic },
      }),
    }),
    weblinksDeleteWeblink: build.mutation<
      WeblinksDeleteWeblinkApiResponse,
      WeblinksDeleteWeblinkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/weblinks/weblink/${queryArg.weblinkId}`,
        method: 'DELETE',
      }),
    }),
    weblinksGetWeblink: build.query<WeblinksGetWeblinkApiResponse, WeblinksGetWeblinkApiArg>({
      query: (queryArg) => ({ url: `/api/v1/extensions/weblinks/weblink/${queryArg.weblinkId}` }),
    }),
    weblinksUpdateWeblinks: build.mutation<
      WeblinksUpdateWeblinksApiResponse,
      WeblinksUpdateWeblinksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/weblinks/weblink/${queryArg.weblinkId}`,
        method: 'PUT',
        body: queryArg.updateWeblinkRequest,
      }),
    }),
    weblinksViewWeblink: build.mutation<WeblinksViewWeblinkApiResponse, WeblinksViewWeblinkApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/weblinks/weblink/${queryArg.weblinkId}/view`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type WeblinksCreateWeblinkApiResponse = /** status 200 Successful Response */ any
export type WeblinksCreateWeblinkApiArg = {
  createWeblinkRequest: CreateWeblinkRequest
}
export type WeblinksGetWeblinksApiResponse = /** status 200 Successful Response */ any
export type WeblinksGetWeblinksApiArg = {
  query?: string | null
  isPublic?: boolean | null
}
export type WeblinksDeleteWeblinkApiResponse = /** status 204 Successful Response */ undefined
export type WeblinksDeleteWeblinkApiArg = {
  weblinkId: number
}
export type WeblinksGetWeblinkApiResponse = /** status 200 Successful Response */ any
export type WeblinksGetWeblinkApiArg = {
  weblinkId: number
}
export type WeblinksUpdateWeblinksApiResponse = /** status 200 Successful Response */ any
export type WeblinksUpdateWeblinksApiArg = {
  weblinkId: number
  updateWeblinkRequest: UpdateWeblinkRequest
}
export type WeblinksViewWeblinkApiResponse = /** status 200 Successful Response */ any
export type WeblinksViewWeblinkApiArg = {
  weblinkId: number
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type CreateWeblinkRequest = {
  link: string
  title?: string | null
  tags: string[]
  shared_with: number[]
}
export type UpdateWeblinkRequest = {
  link?: string | null
  title?: string | null
  is_public?: boolean | null
  tags?: string[] | null
  shared_with?: number[] | null
}
export const {
  useWeblinksCreateWeblinkMutation,
  useWeblinksGetWeblinksQuery,
  useLazyWeblinksGetWeblinksQuery,
  useWeblinksDeleteWeblinkMutation,
  useWeblinksGetWeblinkQuery,
  useLazyWeblinksGetWeblinkQuery,
  useWeblinksUpdateWeblinksMutation,
  useWeblinksViewWeblinkMutation,
} = injectedRtkApi
