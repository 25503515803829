import React from 'react'
import { init as sentryInit, reactRouterV6Instrumentation } from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { useNavigationType } from 'react-router-dom'
import { createRoutesFromChildren, matchRoutes, useLocation } from '@lib/router'
import { MODE, SENTRY } from './config'

sentryInit({
  dsn: 'https://7fb4f4afd3a444fbb99c0a886da14c00@o1221558.ingest.sentry.io/6458711',
  environment: MODE,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: 1.0,
  release: 'staging 1.1',
  enabled: SENTRY,
})
