import { Box, Paper as PaperMui, Button as ButtonMui } from '@common/components'
import { styled } from '@common/utils/styles'

export const Card = styled(PaperMui)({
  width: '100%',
  height: '150px',
  background: '#40576D12',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '15px',
  borderRadius: '10px',
})
export const CardAction = styled(Box)({
  backdropFilter: 'blur(0.5px)',
  position: 'absolute',
  top: 0,
  right: 0,
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const PaperNote = styled(PaperMui, {
  shouldForwardProp: (prop) => prop !== 'background',
})(({ background }) => ({
  position: 'relative',
  background: background ? background : '#FFF0C8',
  height: '100%',
  width: '100%',
  padding: '1px 14px',
}))

export const FolderButton = styled(ButtonMui, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active }) => ({
  borderRadius: '5px',
  maxWidth: '140px',
  width: '100%',
  margin: 'auto 0',
  backgroundColor: active ? '#0057E3' : 'white',
  color: active ? 'white' : '#0057E3',
  ':hover': { background: active ? '#0057E3' : 'white' },
  border: '1px solid #0057E3',
}))
