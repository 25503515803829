import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import {
  InputAdornment,
  TextField,
  inputBaseClasses,
  Popover,
  Typography,
  Stack,
  Button,
  Box,
  IconButton,
  Avatar,
  PaperProps,
  Tooltip,
  CircularProgress,
} from '@common/components'
import {
  ChatContainer,
  ChatHistory,
  ChatMessageContainer,
  LoadingOrErrorContainer,
  MessageTypo,
  MessageTypoContainer,
} from './chat.styles'
import {
  CheckCircleIcon,
  CheckCircleOutlineIcon,
  ErrorIcon,
  InsertEmoticonIcon,
  SendIcon,
  TranslateIcon,
} from '@common/icons'
import { ChatMessage as ChatMessageType, ChatUser } from '@types'
import EmojiPicker from 'emoji-picker-react'
import { useTheme } from '@features/theme/theme.hooks'

type ChatProps = PaperProps & {
  fullScreen?: boolean
  disabled?: boolean
  users: ChatUser[]
  messages: ChatMessageType[]
  onSendMessage: (message: string) => void
}

const Chat = React.forwardRef<HTMLDivElement, ChatProps>((props, ref) => {
  const { messages, onSendMessage, users, fullScreen, disabled, ...restProps } = props
  const messagesEndRef = useRef<HTMLDivElement | null>(null)
  const [message, setMessage] = useState<string>('')

  const handleSubmit = () => {
    onSendMessage(message)
    setMessage('')
  }

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTo({
        top: messagesEndRef.current.scrollHeight,
        behavior: 'smooth',
      })
    }
  }

  React.useEffect(() => {
    scrollToBottom()
  }, [messages])

  return (
    <ChatContainer ref={ref} fullScreen={fullScreen} {...restProps}>
      <ChatHistory ref={messagesEndRef} sx={{ mb: 1 }}>
        {messages?.map((msg, k) => {
          const user = users.find((user) => user?.id === msg.userId)
          // return msg.message
          console.log(user)
          return (
            <ChatMessage
              key={k}
              type={msg.type}
              message={msg.message}
              loading={msg.loading}
              error={msg.error}
              success={msg.success}
              avatar={user?.avatar}
              // username={user?.username}
              // onTranslate={elem.onTranslate}
              // buttonText={elem.buttonText}
              sx={{ mb: 1.5 }}
            />
          )
        })}
      </ChatHistory>
      <MessageToSend
        disabled={disabled}
        message={message}
        onChange={setMessage}
        onSubmit={handleSubmit}
      />
    </ChatContainer>
  )
})

interface ChatMessageProps {
  type: 'SENT' | 'RECEIVED'
  message: string
  avatar: string
  username: string
  loading?: boolean
  error?: boolean
  success?: boolean
}

const ChatMessage = (props: ChatMessageProps) => {
  const { type, username, message, avatar, loading, error, success, ...restProps } = props
  const { theme } = useTheme()
  const variants = {
    initial: {
      opacity: 0.5,
      y: 10,
    },
    animate: {
      y: 0,
      opacity: 1,
    },
  }
  if (type === 'RECEIVED') {
    return (
      <ChatMessageContainer
        component={motion.div}
        variants={variants}
        initial='initial'
        animate='animate'
        position='left'
        {...restProps}
      >
        <Tooltip title={username}>
          <Avatar sx={{ mr: 1 }} src={avatar} />
        </Tooltip>
        <Stack>
          <MessageTypoContainer backgroundColor='#0F82FF'>
            <MessageTypo variant='body2' align='center'>
              {message}
            </MessageTypo>
          </MessageTypoContainer>
          <Button
            startIcon={<TranslateIcon fontSize='small' />}
            variant='text'
            size='small'
            sx={{ alignSelf: 'self-start', fontSize: '0.5rem' }}
            // onClick={() => onTranslate(message)}
          >
            Translate
          </Button>
        </Stack>
      </ChatMessageContainer>
    )
  }
  if (type === 'SENT') {
    return (
      <ChatMessageContainer
        component={motion.div}
        variants={variants}
        initial='initial'
        animate='animate'
        position='right'
        {...restProps}
      >
        {loading ? (
          <Tooltip title='in progress'>
            <CircularProgress size={15} thickness={5} sx={{ mr: 1, color: 'white' }} />
          </Tooltip>
        ) : success ? (
          <Tooltip title='Sent'>
            <CheckCircleIcon sx={{ mr: 1, fontSize: '15px' }} />
          </Tooltip>
        ) : (
          error && (
            <Tooltip title='Message failed to send.' arrow>
              <ErrorIcon
                sx={{
                  color: theme.palette.error.main,
                  mr: 1,
                  fontSize: '15px',
                }}
              />
            </Tooltip>
          )
        )}
        <MessageTypoContainer backgroundColor={'#001835'}>
          <MessageTypo variant='body2' align='center'>
            {message}
          </MessageTypo>
        </MessageTypoContainer>
      </ChatMessageContainer>
    )
  }
  return null
}

const MessageToSend = ({ message, onChange, onSubmit, disabled }) => {
  const handleChange = (e) => {
    onChange(e.target.value)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit(message)
    onChange('')
  }
  const insertEmoji = (emoji) => {
    onChange(`${message}${emoji}`)
  }

  return (
    <Box component='form' onSubmit={handleSubmit} sx={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        fullWidth
        value={message}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <EmojiPickerButton onEmojiClick={insertEmoji} />
            </InputAdornment>
          ),
        }}
        color='primary'
        sx={{
          mr: 1,
          [`& .${inputBaseClasses.root}`]: {
            height: '40px',
          },
        }}
      />
      <Button
        disabled={typeof disabled === 'boolean' ? disabled || message === '' : message === ''}
        variant='contained'
        color='primary'
        type='submit'
        sx={{ minWidth: '40px', minHeight: '40px', borderRadius: '12px' }}
      >
        <SendIcon style={{ fontSize: '18px' }} />
      </Button>
    </Box>
  )
}

const EmojiPickerButton = ({ onEmojiClick }) => {
  const { isDark, theme } = useTheme()
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  const handleEmojiClick = (emoji, e) => {
    onEmojiClick(emoji.emoji)
    handleClose()
  }

  return (
    <>
      <IconButton ref={anchorEl} onClick={handleClick}>
        <InsertEmoticonIcon style={{ color: theme.palette.primary.main }} />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{ transform: 'translateX(30px)' }}
      >
        <EmojiPicker
          width={300}
          height={400}
          lazyLoadEmojis
          theme={isDark ? 'dark' : 'light'}
          previewConfig={{ showPreview: false }}
          onEmojiClick={handleEmojiClick}
        />
      </Popover>
    </>
  )
}

Chat.displayName = 'Chat'
export default Chat
