import { Card, Chip, styled } from '@mui/material'

export const CardWrapper = styled(Card, {
  shouldForwardProp: (prop) =>
    prop !== 'fullWidth' && prop !== 'backgroundColor' && prop !== 'active',
})<{ background: string; fullWidth?: boolean }>(({ theme, fullWidth, background, active }) => ({
  background,
  borderRadius: '20px',
  width: fullWidth ? '100%' : '350px',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'outline 0.1s linear',
  outline: active
    ? `solid 3px  ${theme.palette.tertiary.main}`
    : `solid 0px  ${theme.palette.tertiary.main}`,
  marginTop: '10px',
  '&:hover': {
    outlineWidth: '1px',
    background: '#5556c729',
  },
}))

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'background',
})<{ background: string }>(({ background }) => ({
  color: 'white',
  background,
  minWidth: 60,
  verticalAlign: 'baseline',
}))
