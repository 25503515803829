import { SVGProps } from 'react'
const StatisticIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={23} height={22} fill='none' {...props}>
    <path
      stroke={props.color ? props.color : '#000'}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={3}
      d='M21 20H3.689c-.591 0-.887 0-1.113-.109a1.03 1.03 0 0 1-.46-.437c-.116-.214-.116-.494-.116-1.054V2m17.944 5-4.136 4.183c-.157.158-.235.237-.33.278a.553.553 0 0 1-.267.044c-.103-.01-.205-.06-.409-.16l-3.438-1.69c-.203-.1-.305-.15-.409-.16a.554.554 0 0 0-.267.044c-.094.04-.172.12-.33.278L6.223 14'
    />
  </svg>
)
export default StatisticIcon
