import { Avatar, Box, Container, Grid, Paper, Stack, Typography } from '@common/components'
import { SidebarDashboard } from '../sidebar-dashboard'
import { DashboardTabs } from '../dashboard-tabs'
import { Outlet, useLocation, useParams } from '@lib/router'
import { FEEDBACK_ROUTE, SUMMARY_ROUTE, TABLE_VIEW_ROUTE } from '@common/constants/routes'
import { useUser } from '@features/auth/hooks'
import SidebarAdminDashboard from '../sidebar-admin-dashboard'
import { AnimatePresence, motion } from 'framer-motion'

const AdminDashboardLayout = () => {
  const location = useLocation()
  const { feedback_id } = useParams()
  const { user } = useUser()
  return (
    <>
      {/* {isUNSW() ? <UnswBackground /> : null} */}
      <Container maxWidth={'xl'} sx={{ mb: 6, mx: 'auto', mt: 1 }}>
        <Grid container spacing={4} sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}>
          {/* Sidebar */}
          {location.pathname !== TABLE_VIEW_ROUTE && (
            <Grid item xs={12} md={3.5}>
              <motion.div
                initial={{ x: '-100%', opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: '-100%', opacity: 0 }}
                transition={{ duration: 0.3 }}
                style={{ position: 'relative' }}
              >
                <SidebarAdminDashboard />
              </motion.div>
            </Grid>
          )}
          {/* {location.pathname !== TABLE_VIEW_ROUTE && (
            <Grid item xs={12} md={3.5}>
              <SidebarAdminDashboard />
            </Grid>
          )} */}
          <Grid item xs={12} md={location.pathname === TABLE_VIEW_ROUTE ? 12 : 8.5}>
            <Paper
              sx={{
                borderRadius: '20px',
                height: 'calc(100vh - 150px)',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              <DashboardTabs
                navLinks
                value={
                  location.pathname.startsWith(FEEDBACK_ROUTE) ||
                  location.pathname.startsWith(SUMMARY_ROUTE)
                    ? location.pathname.startsWith(FEEDBACK_ROUTE)
                      ? feedback_id
                        ? FEEDBACK_ROUTE + `/${feedback_id}`
                        : FEEDBACK_ROUTE
                      : feedback_id
                      ? SUMMARY_ROUTE + `/${feedback_id}`
                      : SUMMARY_ROUTE
                    : location.pathname
                }
              />
              <Box
                sx={{
                  p: 3,
                  flex: 1,
                  overflowY: location.pathname.startsWith(FEEDBACK_ROUTE) ? 'hidden' : 'auto',
                }}
              >
                <Box sx={{ height: '100%' }}>
                  <Outlet />
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default AdminDashboardLayout
