import { Avatar, Stack, Typography } from '@common/components'
import { handleGravatar } from '@common/utils/gravatar'
import { useUser } from '@features/auth/hooks'
import { Paper, SessionsWrapper } from './sidebar-dashboard.styles'
import { isUNSW } from '../../../utils/unswUtils'
import DisplaySessions from '@features/booking/display-sessions'

const SidebarDashboard = (props) => {
  const { user } = useUser()
  return (
    <Stack sx={{ height: 'calc(100vh - 150px)' }} {...props}>
      <Stack
        spacing={2}
        sx={{ mb: 2, display: { xs: 'none', md: 'flex' }, flexDirection: 'column' }}
      >
        <Paper sx={{ height: '80px', display: 'flex', justifyContent: 'flex-start' }}>
          <Avatar src={handleGravatar(user?.image)} />
          <Stack>
            <Typography sx={{ ml: 1 }}>
              Welcome back{' '}
              <span style={{ fontWeight: 600 }}>{user?.full_name ?? user?.username}</span>
            </Typography>
          </Stack>
        </Paper>
        {/* {!isUNSW() ? <NextSessionTime /> : <DisplayClassroom />} */}
      </Stack>
      <SessionsWrapper>
        <Paper
          sx={{
            flex: 1,
            overflowY: 'scroll',
            '::-webkit-scrollbar': {
              height: '10px',
              width: '7px',
              backgroundColor: 'transparent',
            },
            '::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(80, 80, 80, 0.1)',
            },
            '::-webkit-scrollbar-thumb': {
              borderRadius: '5px',
              backgroundColor: isUNSW() ? 'rgba(30, 30, 30, 0.5)' : '#909090',
            },
          }}
        >
          <DisplaySessions />
        </Paper>
      </SessionsWrapper>
    </Stack>
  )
}

export default SidebarDashboard
