import { SVGProps } from 'react'
const TabsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={22} fill='none' {...props}>
    <path
      stroke={props.color ? props.color : '#747474'}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={3}
      d='M2 8h20M8.667 2v18M7.333 2h9.334c1.866 0 2.8 0 3.513.327a3.187 3.187 0 0 1 1.457 1.311C22 4.28 22 5.12 22 6.8v8.4c0 1.68 0 2.52-.363 3.162a3.187 3.187 0 0 1-1.457 1.311c-.713.327-1.646.327-3.513.327H7.333c-1.867 0-2.8 0-3.513-.327a3.187 3.187 0 0 1-1.457-1.311C2 17.72 2 16.88 2 15.2V6.8c0-1.68 0-2.52.363-3.162.32-.564.83-1.023 1.457-1.311C4.533 2 5.466 2 7.333 2Z'
    />
  </svg>
)
export default TabsIcon
