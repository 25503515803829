import {
  Button,
  InputAdornment,
  inputBaseClasses,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@common/components'
import { SearchIcon } from '@common/icons'
import React from 'react'

const DashboardFilter = () => {
  const [searchTerm, setSearchTerm] = React.useState('')

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  return (
    <>
      <TextField
        size='small'
        id='search-request'
        defaultValue={''}
        fullWidth
        color='tertiary'
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder='Search  ...'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{
          [`& .${inputBaseClasses.root}`]: {
            marginTop: '15px',
          },
        }}
      />
      <Stack direction={'row'} alignItems={'center'} sx={{ width: '100%', mt: 3 }} spacing={3}>
        <Typography sx={{ width: '35%', pl: 0.5 }}>Year</Typography>
        <Select size='small' defaultValue={'All'} fullWidth>
          <MenuItem value={'All'}>All</MenuItem>
        </Select>
      </Stack>
      <Stack direction={'row'} alignItems={'center'} sx={{ width: '100%', mt: 3 }} spacing={3}>
        <Typography sx={{ width: '35%', pl: 0.5 }}>Cohort</Typography>
        <Select size='small' defaultValue={'All'} fullWidth>
          <MenuItem value={'All'}>All</MenuItem>
        </Select>
      </Stack>
      <Stack direction={'row'} alignItems={'center'} sx={{ width: '100%', mt: 3 }} spacing={3}>
        <Typography sx={{ width: '35%', pl: 0.5 }}>Teacher</Typography>
        <Select size='small' defaultValue={'All'} fullWidth>
          <MenuItem value={'All'}>All</MenuItem>
        </Select>
      </Stack>
      <Stack direction={'row'} alignItems={'center'} sx={{ width: '100%', mt: 3 }} spacing={3}>
        <Typography sx={{ width: '35%', pl: 0.5 }}>Student</Typography>
        <Select size='small' defaultValue={'All'} fullWidth>
          <MenuItem value={'All'}>All</MenuItem>
        </Select>
      </Stack>
      <Button variant='contained' color='neutral' sx={{ ml: 'auto', mt: 3 }}>
        Filter
      </Button>
    </>
  )
}

export default DashboardFilter
