import React from 'react'
import {
  Box,
  Grid,
  Paper,
  Avatar,
  Button,
  Stack,
  Typography,
  ButtonProps,
} from '@common/components'
import { handleGravatar } from '@common/utils/gravatar'
import { useUser } from '@features/auth/hooks'
import { PermIdentityOutlinedIcon, CheckCircleOutlineIcon, GTranslateIcon } from '@common/icons'
import { RouterLink, useLocation } from '@lib/router'
import { IconContainer } from './sidebar.styles'
import { PERSONAL_ROUTE, PREFERRED_ACCENT_ROUTE } from '@common/constants/routes'

interface SidebarProps {
  background?: boolean
}

const Sidebar: React.FC<SidebarProps> = (props) => {
  const { background = true } = props
  const { user, isTeacher } = useUser()

  return (
    <Grid item xs={12} md={4}>
      <Container background={background}>
        <Stack alignItems='center'>
          <Avatar src={handleGravatar(user?.image)} />
          <Typography variant='h6' sx={{ my: 1 }}>
            {user?.username}
          </Typography>
        </Stack>
        <Typography sx={{ fontWeight: '500', mt: 4, mb: 2 }}>General</Typography>
        <Stack alignItems='flex-start' spacing={1}>
          <LinkButton
            to={PERSONAL_ROUTE}
            icon={<PermIdentityOutlinedIcon />}
            label={'Personal Information'}
            // missing={isModuleMissing('UserAddressModule')}
          />
          {!isTeacher && (
            <LinkButton
              to={PREFERRED_ACCENT_ROUTE}
              icon={<GTranslateIcon />}
              label={'Preferred Accent'}
              // missing={isModuleMissing('PreferredAccentModule')}
            />
          )}
        </Stack>
      </Container>
    </Grid>
  )
}

const Container = ({ background, children }) => {
  if (background) {
    return (
      <Paper
        sx={{
          borderRadius: '20px',
          p: 3,
          height: '100%',
        }}
      >
        {children}
      </Paper>
    )
  }
  return <Box sx={{ p: 3 }}>{children}</Box>
}

const LinkButton = <C extends React.ElementType>(
  props: {
    disabled?: boolean
    label: string
    icon: React.ReactNode
  } & ButtonProps<C, { component?: C }>,
): React.ReactElement => {
  const { to, label, icon, missing, disabled = false } = props
  const { pathname } = useLocation()
  const isActive = pathname === to
  return (
    <Button
      disabled={disabled}
      color='neutral'
      sx={{
        background: isActive ? '#5556c750' : 'none',
        width: '100%',
        ': hover': {
          background: isActive ? '#5556c730' : 'rgba(230,230,230,0.3)',
        },
      }}
      component={RouterLink}
      preventScrollReset={true}
      to={to}
    >
      <Stack direction='row' alignItems='center' spacing={1} sx={{ width: '100%' }}>
        <IconContainer alignItems='center' justifyContent='center'>
          {icon}
        </IconContainer>
        <span style={{ textTransform: 'initial' }}>{label}</span>
        <Box sx={{ flexGrow: 1 }} />
        {typeof missing === 'boolean' && (
          <>
            {missing ? (
              <Typography component='span' variant='body1' color='error'>
                Missing
              </Typography>
            ) : (
              <CheckCircleOutlineIcon color='success' />
            )}
          </>
        )}
      </Stack>
    </Button>
  )
}

export default Sidebar
