import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    documentsCreateNewDocument: build.mutation<
      DocumentsCreateNewDocumentApiResponse,
      DocumentsCreateNewDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/documents/document`,
        method: 'POST',
        body: queryArg.createNewDocumentRequest,
      }),
    }),
    documentsGetDocumentById: build.query<
      DocumentsGetDocumentByIdApiResponse,
      DocumentsGetDocumentByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/documents/document/${queryArg.documentId}`,
      }),
    }),
    documentsDeleteDocumentById: build.mutation<
      DocumentsDeleteDocumentByIdApiResponse,
      DocumentsDeleteDocumentByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/documents/document/${queryArg.documentId}`,
        method: 'DELETE',
      }),
    }),
    documentsGetDocuments: build.query<
      DocumentsGetDocumentsApiResponse,
      DocumentsGetDocumentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/documents/documents`,
        params: { query: queryArg.query },
      }),
    }),
    documentsSplitDocument: build.mutation<
      DocumentsSplitDocumentApiResponse,
      DocumentsSplitDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/documents/split_document`,
        method: 'POST',
        body: queryArg.splitDocumentRequest,
      }),
    }),
    documentsGetSplitDocumentStatus: build.query<
      DocumentsGetSplitDocumentStatusApiResponse,
      DocumentsGetSplitDocumentStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/documents/split_document/${queryArg.jobId}`,
      }),
    }),
    documentsGetDocumentPages: build.query<
      DocumentsGetDocumentPagesApiResponse,
      DocumentsGetDocumentPagesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/documents/document/${queryArg.documentId}/pages`,
      }),
    }),
    documentsExportScene: build.mutation<
      DocumentsExportSceneApiResponse,
      DocumentsExportSceneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/documents/export_images_to_pdf`,
        method: 'POST',
        body: queryArg.bodyExportSceneApiV1ExtensionsDocumentsExportImagesToPdfPost,
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type DocumentsCreateNewDocumentApiResponse =
  /** status 201 Successful Response */ DocumentsDbModel
export type DocumentsCreateNewDocumentApiArg = {
  createNewDocumentRequest: CreateNewDocumentRequest
}
export type DocumentsGetDocumentByIdApiResponse =
  /** status 200 Successful Response */ DocumentsDbModel
export type DocumentsGetDocumentByIdApiArg = {
  documentId: number
}
export type DocumentsDeleteDocumentByIdApiResponse = /** status 204 Successful Response */ undefined
export type DocumentsDeleteDocumentByIdApiArg = {
  documentId: number
}
export type DocumentsGetDocumentsApiResponse =
  /** status 200 Successful Response */ DocumentsDbModel[]
export type DocumentsGetDocumentsApiArg = {
  query?: string | null
}
export type DocumentsSplitDocumentApiResponse =
  /** status 201 Successful Response */ SplitDocumentResponse
export type DocumentsSplitDocumentApiArg = {
  splitDocumentRequest: SplitDocumentRequest
}
export type DocumentsGetSplitDocumentStatusApiResponse =
  /** status 200 Successful Response */ StatusResponse
export type DocumentsGetSplitDocumentStatusApiArg = {
  jobId: string
}
export type DocumentsGetDocumentPagesApiResponse =
  /** status 200 Successful Response */ DocumentsToImagesDbModel[]
export type DocumentsGetDocumentPagesApiArg = {
  documentId: number
}
export type DocumentsExportSceneApiResponse =
  /** status 200 Successful Response */ ExportImageToPdfResponse
export type DocumentsExportSceneApiArg = {
  bodyExportSceneApiV1ExtensionsDocumentsExportImagesToPdfPost: BodyExportSceneApiV1ExtensionsDocumentsExportImagesToPdfPost
}
export type DocumentsDbModel = {
  id: number
  user_id: number
  document_type: string
  title?: string | null
  description?: string | null
  link: string
  preview?: string | null
  created_at?: string
  updated_at?: string
  deleted?: boolean
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type CreateNewDocumentRequest = {
  link: string
  document_type?: string | null
  title?: string | null
  description?: string | null
}
export type SplitDocumentResponse = {
  job_id: string
}
export type SplitDocumentRequest = {
  document_id: number
}
export type StatusResponse = {
  status: string
}
export type DocumentsToImagesDbModel = {
  id: number
  created_at?: string
  updated_at?: string
  image_link?: string | null
  document_id: number
  page_number: number
  height?: number | null
  width?: number | null
}
export type ExportImageToPdfResponse = {
  link: string
}
export type BodyExportSceneApiV1ExtensionsDocumentsExportImagesToPdfPost = {
  files: Blob[]
}
export const {
  useDocumentsCreateNewDocumentMutation,
  useDocumentsGetDocumentByIdQuery,
  useLazyDocumentsGetDocumentByIdQuery,
  useDocumentsDeleteDocumentByIdMutation,
  useDocumentsGetDocumentsQuery,
  useLazyDocumentsGetDocumentsQuery,
  useDocumentsSplitDocumentMutation,
  useDocumentsGetSplitDocumentStatusQuery,
  useLazyDocumentsGetSplitDocumentStatusQuery,
  useDocumentsGetDocumentPagesQuery,
  useLazyDocumentsGetDocumentPagesQuery,
  useDocumentsExportSceneMutation,
} = injectedRtkApi
