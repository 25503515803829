import { store } from '@app/store'
import { API_BASE_URL } from '@config'
import { refreshTokenIfExpired } from '@features/auth/utils/refresh-token-if-expired'

let ws: WebSocket | null
let currentEventId: string | null
let listeners = new Set<(ev: MessageEvent) => void>()

const createSocket = async (
  eventId: string,
  messageListner: (ev: MessageEvent) => void,
  force?: boolean,
) => {
  await refreshTokenIfExpired()
  console.log('DEBUG', 'createSocket')
  if (!force) {
    listeners.add(messageListner)
  }
  console.log('DEBUG', 'createSocket', messageListner, listeners.size)
  const tokens = store.getState().auth.tokens
  if (!ws || currentEventId !== eventId || force) {
    ws?.close(3000)
    currentEventId = eventId
    ws = new WebSocket(
      `${API_BASE_URL.replace('http', 'ws')}/api/v1/sdk/event/ws?token=${
        tokens?.access_token?.token
      }&event_id=${eventId}`,
    )
    const closeListner = async (event) => {
      console.log(event)
      // TODO: this is just a temporary solution to handle server disconnect, fix later (using short event id length)
      if (event.code !== 3000 && eventId.length !== 4) {
        setTimeout(async () => {
          ws = await createSocket(
            eventId,
            (event_: MessageEvent) => {
              for (const listener of listeners) {
                listener(event_)
              }
            },
            true,
          )
        }, 3000)
      }
    }
    ws.addEventListener('close', closeListner)
  }
  ws.addEventListener('message', messageListner)
  return ws
}

export { createSocket }
