import { Avatar, Box, Paper, Stack, Typography } from '@common/components'
import { useUser } from '@features/auth/hooks'
import { isUNSW } from '../../../utils/unswUtils'
import DisplaySessions from '@features/booking/display-sessions'
import DashboardFilter from '@features/(admin)/components/dashboard-filter'

const SidebarAdminDashboard = (props) => {
  const { user } = useUser()
  return (
    <Stack sx={{ height: 'calc(100vh - 150px)' }} {...props}>
      <Box
        sx={{
          borderRadius: '20px',
          height: 'calc(100vh - 150px)',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          justifyContent: 'flex-start',
        }}
      >
        <Paper
          sx={{
            padding: '15px',
            // width: '100%',
            // height: "100px",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            borderRadius: '10px',
            flex: 1,
            overflowY: 'scroll',
            '::-webkit-scrollbar': {
              height: '10px',
              width: '7px',
              backgroundColor: 'transparent',
            },
            '::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(80, 80, 80, 0.1)',
            },
            '::-webkit-scrollbar-thumb': {
              borderRadius: '5px',
              backgroundColor: isUNSW() ? 'rgba(30, 30, 30, 0.5)' : '#909090',
            },
          }}
        >
          <DashboardFilter />
        </Paper>
      </Box>
    </Stack>
  )
}

export default SidebarAdminDashboard
