import { Avatar, Box, Container, Grid, Paper, Stack, Typography } from '@common/components'
import { SidebarDashboard } from '../sidebar-dashboard'
import { DashboardTabs } from '../dashboard-tabs'
import { Outlet, useLocation, useParams } from '@lib/router'
import { FEEDBACK_ROUTE, SUMMARY_ROUTE } from '@common/constants/routes'
import { useUser } from '@features/auth/hooks'
import { handleGravatar } from '@common/utils/gravatar'
import AdminDashboardLayout from '../admin-dashboard-layout'

const DashboardLayout = () => {
  const { user } = useUser()
  const hasSuperAdminRole = user?.roles && user?.roles.some((role) => role.name === 'SuperAdmin')
  return (
    <>
      {/* {isUNSW() ? <UnswBackground /> : null} */}
      {hasSuperAdminRole ? <AdminDashboardLayout /> : <Dashboard />}
    </>
  )
}

const Dashboard = () => {
  const location = useLocation()
  const { feedback_id } = useParams()
  const { user } = useUser()
  return (
    <Container maxWidth={'xl'} sx={{ mb: 6, mx: 'auto', mt: 1 }}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        sx={{ display: { xs: 'flex', md: 'none' }, mb: 2 }}
      >
        <Paper
          sx={{
            padding: '15px',
            width: { xs: '100%', sm: '50%' },
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            borderRadius: '10px',
            height: { xs: '70px', sm: 'auto' },
            justifyContent: 'flex-start',
          }}
        >
          <Avatar
            sx={{
              '.MuiAvatar-root': {
                color: 'black',
              },
            }}
            src={handleGravatar(user?.image)}
          />
          <Stack>
            <Typography sx={{ ml: 1 }}>
              Welcome back{' '}
              <Box
                component='span'
                sx={{
                  fontWeight: 600,
                  maxWidth: { xs: '250px', sm: '170px' },
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block', // Make sure it stays inline
                  verticalAlign: 'bottom', // Align properly with the text
                }}
              >
                {user?.full_name ?? user?.username}
              </Box>
            </Typography>
          </Stack>
        </Paper>
        {/* <DisplayClassroom /> */}
      </Stack>
      <Grid container spacing={4} sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}>
        <Grid item xs={12} md={3.5}>
          <SidebarDashboard />
        </Grid>
        <Grid item xs={12} md={8.5}>
          <Paper
            sx={{
              borderRadius: '20px',
              height: 'calc(100vh - 150px)',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            <DashboardTabs
              navLinks
              value={
                location.pathname.startsWith(FEEDBACK_ROUTE) ||
                location.pathname.startsWith(SUMMARY_ROUTE)
                  ? location.pathname.startsWith(FEEDBACK_ROUTE)
                    ? feedback_id
                      ? FEEDBACK_ROUTE + `/${feedback_id}`
                      : FEEDBACK_ROUTE
                    : feedback_id
                    ? SUMMARY_ROUTE + `/${feedback_id}`
                    : SUMMARY_ROUTE
                  : location.pathname
              }
            />
            <Box
              sx={{
                p: 3,
                flex: 1,
                overflowY: location.pathname.startsWith(FEEDBACK_ROUTE) ? 'hidden' : 'auto',
                '::-webkit-scrollbar': {
                  height: '10px',
                  width: '7px',
                  backgroundColor: 'transparent',
                },
                '::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(80, 80, 80, 0.1)',
                },
                '::-webkit-scrollbar-thumb': {
                  borderRadius: '5px',
                  backgroundColor: 'rgba(30, 30, 30, 0.5)',
                },
              }}
            >
              <Box>
                <Outlet />
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
export default DashboardLayout
