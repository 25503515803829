import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={21} height={21} fill='none' {...props}>
    <path
      stroke={props.color ? props.color : '#000'}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={3}
      d='m10.5 18.375-.087-.131c-.608-.912-.912-1.368-1.313-1.698a3.5 3.5 0 0 0-1.206-.645c-.497-.151-1.045-.151-2.14-.151H4.55c-.98 0-1.47 0-1.844-.19a1.75 1.75 0 0 1-.765-.766c-.19-.374-.19-.864-.19-1.844V5.425c0-.98 0-1.47.19-1.844a1.75 1.75 0 0 1 .765-.765c.374-.191.864-.191 1.844-.191h.35c1.96 0 2.94 0 3.69.381a3.5 3.5 0 0 1 1.529 1.53c.382.749.382 1.729.382 3.689m0 10.15V8.225m0 10.15.087-.131c.608-.912.912-1.368 1.313-1.698a3.498 3.498 0 0 1 1.206-.645c.497-.151 1.045-.151 2.14-.151h1.204c.98 0 1.47 0 1.844-.19a1.75 1.75 0 0 0 .765-.766c.19-.374.19-.864.19-1.844V5.425c0-.98 0-1.47-.19-1.844a1.75 1.75 0 0 0-.765-.765c-.374-.191-.864-.191-1.845-.191h-.35c-1.96 0-2.94 0-3.689.381a3.5 3.5 0 0 0-1.529 1.53c-.381.749-.381 1.729-.381 3.689'
    />
  </svg>
)
export default SvgComponent
